<!-- 一级级目录 -->
<template>
  <div class="dxby-home-conten">
    <ul>
      <li
        v-for="(folderItem, key) in $store.state.freshFolderListData"
        :key="key"
      >
        <router-link
          :to="{path: '/folder/child', query: {filePath: folderItem.filePath}}"
          class="dxby-home-folder"
          v-if="folderItem.directory"
        >
          <img :src="isFolderIcon" alt="">
          <i>{{folderItem.fileName}}</i>
        </router-link>
        <a
          :href="folderItem.filePath"
          class="dxby-home-folder"
          title="点击下载"
          v-if="folderItem.excelFile"
        >
          <img :src="isXlsIcon" alt="">
          <i>{{folderItem.fileName}}</i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { xlsIcon, FolderIcon } from '@/utils/iconManage'

export default {
  name: 'FolderOrFile',
  setup () {
    const isXlsIcon = ref(xlsIcon)
    const isFolderIcon = ref(FolderIcon)
    const store = useStore()

    onMounted(async () => store.dispatch('getData', ''))

    return {
      isXlsIcon,
      isFolderIcon
    }
  }
}
</script>

<style scoped lang="less">
@import url('~@/assets/css/file.less');
</style>
